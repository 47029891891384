import React, { useState, useEffect, useContext } from "react";
import MomentUtils from '@date-io/moment'
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Moment from 'react-moment';
import moment from 'moment';

import {
  useParams
} from "react-router-dom";

import './Event.css';
import { GlobalContext } from "./GlobalContext.js";
import { base } from "./Common.js";

function Event({ groupId }) {
  const eventId = useParams().event;
  const { csrfToken, setError } = useContext(GlobalContext);
  const [ event, setEvent ] = useState(undefined);
 
  useEffect(() => {
    if (!csrfToken || event || !eventId || !groupId) {
      return;
    }

    const effect = async () => {
      const res = await window.fetch(
        `${base}/api/v1/events/${eventId}?groupId=${groupId}`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken
        }
      });
      const data = await res.json();
      if (res.status >= 400) {
        setError(data.message ? data.message : res.statusText);
        return;
      }
      if (!data) {
        return;
      }
      setEvent(data[0]);
    }
    effect();
  }, [eventId, groupId, csrfToken, event, setError]);

  const showEvent = (event) => <div className="Event">
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div>Event: </div>
      <div style={{ fontWeight: 'bold' }}>{event.name}</div>
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'row'
    }}>

      <div style={{
        flexDirection: 'column', 
        padding: '0.5rem',
        display: 'flex',
        alignItems: 'flex-start',
        textAlign: 'center',
        height: 'calc(3rem + 146px)',
        minHeight: 'calc(3rem + 146px)',
        width: 'calc(162px)',
        minWidth: 'calc(162px)',
        boxSizing: 'border-box'
      }}>
        <div style={{
          transformOrigin: '0 0', 
          transform: 'scale(0.5)',
          height: 'calc(2rem + 292px)',
          margin: '0 calc((-50% * (1 - 0.5)) / 2) calc((-50% * (1 - 0.5)) / 2) 0'
        }}>
          <Calendar
            variant='static'
            date={moment(event.date)}
            onChange={() => {}}
            maxDate={moment(event.date)}
            minDate={moment(event.date)}
          />
          <div style={{
            fontSize: '2em',
            fontWeight: 'bold'
          }}>
            <Moment value={event.date} format='LT' />
          </div>
        </div>
        
      </div>
      <div>
        <div className='what'>{event.description}</div>
        <div className='when'>
          Next event is occurring <Moment fromNow date={event.date} /> on <Moment format={'MMMM Do YYYY, h: mm: ss a'} date={event.date} />.
        </div>
        { event.votingBegins === 'day-of' && 
          <div>Voting for location begins the day of the event.</div>
        }
        { event.votingBegins !== 'day-of' &&
          <div>Vote any time for the location.</div>
        }
        <div>Voting for location closes at {event.votingCloses}.</div>
      </div>
    </div>
  </div>;
  
  if (!event) {
    return <>No event</>;
  }

  return (
    <div className="Event" style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5rem',
      marginTop: '0.25rem',
      marginBottom: '0.25rem'
    }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        { showEvent(event) }
      </MuiPickersUtilsProvider>
    </div>
  );
}

export { Event };
