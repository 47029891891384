import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import './App.css';
import './fonts/index.css';

import { GoodTimesBar } from "./GoodTimesBar.js";
import { GlobalContext } from "./GlobalContext.js";

import SignIn from "./SignIn.js";
import SignUp from "./SignUp.js";
import Group from "./Group.js";
import VerifyEmail from "./VerifyEmail.js";
import Dashboard from "./Dashboard.js";

import { base } from "./Common.js";

const App = () => {
  const [ user, setUser ] = useState(null);
  const [ csrfToken, setCsrfToken ] = useState(undefined);
  const [ loading, setLoading ] = useState(true);
  const [ googleApiKey, setGoogleApiKey ] = useState(undefined);
  const [ error, setError] = useState(undefined);
  const location = useLocation();

  useEffect(() => {
    console.log(`app - path - `, location.pathname);
    setError(undefined);
  }, [location]);

  useEffect(() => {
    const effect = async () => {
     const res = await window.fetch(`${base}/api/v1/users/csrf`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const data = await res.json();
      setCsrfToken(data.csrfToken);
    }

    effect();
  }, []);

  useEffect(() => {
    if (!csrfToken) {
      return;
    }
    const effect = async () => {
      const res = await window.fetch(`${base}/api/v1/users`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken
        },
      });
      const data = await res.json();
      setLoading(false);
      if (res.status >= 400) {
        console.log(data.message ? data.message : res.statusText);
        return;
      }

      setUser(data);
    };

    effect();
  }, [csrfToken, setUser]);

  useEffect(() => {
    if (!csrfToken || googleApiKey) {
      return;
    }
    const effect = async () => {
      const res = await window.fetch(
        `${base}/api/v1/locations/google-api-key`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken
        }
      });
      const data = await res.json();
      if (res.status >= 400) {
        setError(data.message ? data.message : res.statusText);
        return;
      }
      if (!data) {
        return;
      }
      setGoogleApiKey(data.key);
    }
    effect();
  }, [csrfToken, googleApiKey, setGoogleApiKey]);

  return (
    <div className="App">
      <GlobalContext.Provider value={{
        user, setUser, csrfToken, googleApiKey, 
        setError 
      }}>
        <GoodTimesBar />
        <Container className="Content">
          { error && <Paper style={{padding: "0.5rem"}}>{error}</Paper> }
          <Routes>
            <Route path="/signin" element={<SignIn />}/>
            <Route path="/signup" element={<SignUp />}/>
            <Route path="/user/verify-email/:token" element={<VerifyEmail />}/>
            <Route path="/password" element={
              <Paper>Not implemented... yet.</Paper>
            }/>
            <Route path="/:group/*" element={<Group />}/>
            { user && user.mailVerified &&
              <Route path="/" element={<Dashboard />}/>
            }
            { user && !user.mailVerified &&
              <Route path="/" element={
              <Paper style={{padding: "0.5rem"}}>
                You need to verify your email via the link sent to {user.email}.
              </Paper>}/>
            }
            { !user && !loading &&
              <Route path="/" element={<SignIn />} />
            }
            { !user && loading &&
              <Route path="/" element={<Paper>Loading...</Paper>} />
            }
          </Routes>
        </Container>
      </GlobalContext.Provider>
    </div>
  );
};

export default App;
