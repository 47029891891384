import React, { useState, useEffect, useContext } from "react";
import Paper from '@mui/material/Paper';
import {
  useParams,
  Routes,
  Route
} from "react-router-dom";

import './Group.css';
import { GlobalContext } from "./GlobalContext.js";
import { base } from "./Common.js";
import { Location } from "./Location.js";
import { Event } from "./Event.js";

function Group() {
  const { csrfToken, user, setError } = useContext(GlobalContext);
  const groupId = useParams().group;
  const [ group, setGroup ] = useState(undefined);
  const [ events, setEvents ] = useState(null);
  const [ locations, setLocations ] = useState([]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const effect = async () => {
      const res = await window.fetch(
        `${base}/api/v1/locations/`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken
        }
      });
      const data = await res.json();
      if (res.status >= 400) {
        setError(data.message ? data.message : res.statusText);
        return;
      }
      if (!data) {
        return;
      }
      setLocations(data);
    };
    effect();
  }, [user, setGroup, groupId, csrfToken, setError]);

  useEffect(() => {
    if (!user || !groupId || !csrfToken) {
      return;
    }

    const effect = async () => {
      const res = await window.fetch(
        `${base}/api/v1/groups/${groupId}/events`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken
        }
      });
      const data = await res.json();
      if (res.status >= 400) {
        setError(data.message ? data.message : res.statusText);
        return;
      }
      if (!data) {
        return;
      }
      setEvents(data);
    };
    effect();
  }, [user, setEvents, groupId, csrfToken, setError]);

  useEffect(() => {
    if (!user || !groupId || !csrfToken) {
      return;
    }

    const effect = async () => {
      const res = await window.fetch(
        `${base}/api/v1/groups/${groupId}`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken
        }
      });
      const data = await res.json();
      if (res.status >= 400) {
        setError(data.message ? data.message : res.statusText);
        return;
      }
      if (!data) {
        return;
      }
      setGroup(data[0]);
    };
    effect();
  }, [user, setGroup, groupId, csrfToken, setError]);

  if (!group) {
    return <></>;
  }
  
  return (
    <Paper className="Group" style={{
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    }}>
      <div style={{ fontWeight: 'bold' }}>{group.name}</div>
      <Routes>
        <Route path="/:event" element={<Event groupId={groupId}/>} />
        <Route path="/" element={ group && <>
          <div>Locations</div>
          { locations.map(location =>
            <Location location={location} key={location.id}/>) }
        </> }/>
      </Routes>
    </Paper>
  );
}

export default Group;
