import React, { useContext, useEffect, useState, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import {
  useNavigate,
} from "react-router-dom";

import Gravatar from 'react-gravatar'
import "./GoodTimesBar.css";
import { GlobalContext } from "./GlobalContext.js";
import { base } from "./Common.js";

const GoodTimesBar = () => {
  const { csrfToken, user, setUser, setError } = useContext(GlobalContext);
  const [ settings, setSettings ] = useState([]);
  const [ menu, setMenu] = useState([]);
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleUserMenu = useCallback(async (event, menu) => {
    switch (menu) {
    case 'Sign Out': {
      const res = await window.fetch(
        `${base}/api/v1/users/signout`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken
        }
      });
      const data = await res.json();
      if (res.status >= 400) {
        setError(data.message ? data.message : res.statusText);
        return;
      }
      setUser(null);
      setAnchorElUser(null);
      navigate('/');
      break;
    }

    case 'Dashboard':
      setAnchorElUser(null);
      navigate('/');
      break;

    default: break;
    }
    console.log(event);
  }, [csrfToken, setUser, navigate, setError]);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  console.log(`goodtimes-bar - user - `, user);

  useEffect(() => {
    if (user) {
      setSettings([/*'Profile', 'Account',*/ 'Dashboard', 'Sign Out']
        .map((setting) => (
          <MenuItem className='MenuItem'
            key={`menu-${setting}`} 
            onClick={(e) => handleUserMenu(e, setting)}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        )));
      setMenu(['Dashboard']
        .map((menu) => (
          <MenuItem key={`toolbar-${menu}`} onClick={(e) => handleUserMenu(e, menu)}>
            <Typography textAlign="center">{menu}</Typography>
          </MenuItem>
        )));
    } else {
      setMenu([]);
      setSettings([]);
    }
  }, [user, setSettings, handleUserMenu]);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            GOODTIMES
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            GOODTIMES
          </Typography>

          <Box style={{ flexDirection: 'row', marginLeft: '2rem' }} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            { menu }
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            { !user && <>
              <Tooltip title="Sign In">
                <Button onClick={() => navigate("/signin")} color="inherit">
                Sign In
                </Button>
              </Tooltip>
            </>
            }
            { user && <>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Gravatar alt={user.name} md5={user.md5}/>
                </IconButton>
              </Tooltip>
              <Menu
                id="basic-menu"
                open={Boolean(anchorElUser)}
                anchorEl={anchorElUser}
                onClose={handleCloseUserMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <MenuList
                  className="DashboardMenu"
                  aria-labelledby="composition-button">
                  {settings}
                </MenuList>
              </Menu>
            </>}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export { GoodTimesBar };
