import React, { useState, useEffect, useContext } from "react";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Moment from 'react-moment';

import {
  useParams,
  useNavigate
} from "react-router-dom";

import './Dashboard.css';
import { GlobalContext } from "./GlobalContext.js";
import { base } from "./Common.js";

function Dashboard() {
  const navigate = useNavigate();
  const { csrfToken, user, setUser, setError } = useContext(GlobalContext);
  const [ groups, setGroups ] = useState([]);

  useEffect(() => {
    if (!user || !csrfToken) {
      return;
    }
    const effect = async () => {
      const res = await window.fetch(
        `${base}/api/v1/groups`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken
        }
      });
      const data = await res.json();
      if (res.status >= 400) {
        setError(data.message ? data.message : res.statusText);
        return;
        }
      if (!data) {
        return;
      }
      setGroups(data);
    }
    
    effect();
  }, [user, setGroups, csrfToken, setError ]);

  const upcomingEvents = groups
    .filter(group => group.nextEvent.date > Date.now());
  
  return (
    <div className="Dashboard"
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left'
      }}>
      
      <GlobalContext.Provider value={{user, setUser}}>
        { upcomingEvents && upcomingEvents.length !== 0 && 
          <Paper style={{
            flexDirection: 'column',
            display: 'flex',
            width: '100%',
            marginTop: '0.5rem'
          }}> 
            <div style={{ fontWeight: 'bold', padding: '0.5rem' }}>
              Upcoming events
            </div>

            { upcomingEvents
              .map((group) => {
              return <Button key={group.id} 
                  onClick={() => navigate(`/${group.group}/${group.nextEvent.event}`)}
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    textAlign: 'left',
                    padding: '0 0.5rem 0.5rem 0.5rem'
                  }
                }>
                <div style={{
                  fontWeight: 'bold',
                  marginRight: '0.5rem',
                  maxWidth: '10rem',
                  minWidth: '10rem',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>{group.name}</div>
                { group.nextEvent.date &&
                  <div>Next event <Moment fromNow date={group.nextEvent.date} /> on <Moment format={'MMMM Do YYYY, h: mm: ss a'} date={group.nextEvent.date}/>.</div>
                }
                </Button>;
              })
            }
          </Paper>
        }

        { groups && groups.length !== 0 && 
          <Paper style={{
            flexDirection: 'column',
            display: 'flex',
            width: '100%',
            marginTop: '0.5rem'
          }}> 
            <div style={{ fontWeight: 'bold', padding: '0.5rem' }}>
              Groups
            </div>
            { groups
              .map((group) => {
                return <Button key={group.id}
                  onClick={() => navigate(`/${group.group}`)}
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                    padding: '0 0.5rem 0.5rem 0.5rem'
                  }
                  }>
                  <div key={group.id} style={{
                    fontWeight: 'bold'
                  }}>{group.name}</div>
                </Button>;
              })
            }
          </Paper>
        }
      </GlobalContext.Provider>
    </div>
  );
}

export default Dashboard;
