import React, { useState, useEffect, useContext } from "react";
import {
  useParams,
  useNavigate
} from "react-router-dom";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import './Group.css';
import { GlobalContext } from "./GlobalContext.js";
import { base } from "./Common.js";

function Group() {
  const navigate = useNavigate();
  const { csrfToken } = useContext(GlobalContext);
  const { token } = useParams();
  const [ user, setUser ] = useState(null);
  const [error, setError ] = useState(undefined);

  useEffect(() => {
    if (!token || !csrfToken) {
      return;
    }
    const effect = async () => {
      const res = await window.fetch(`${base}/api/v1/users/verify-email`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken
        },
        body: JSON.stringify({
          token
        })
      });
      const data = await res.json();
      if (res.status >= 400) {
        setError(data.message ? data.message : res.statusText);
        return;
      }
      
      if (!data) {
        return;
      }
      setUser(data);
      navigate("/");
    }

    effect();
  }, [setError, token, csrfToken, navigate]);

  return (
    <Paper className="Group" style={{display: "flex", flexDirection: "column"}}>
      <GlobalContext.Provider value={{user, setUser}}>
        <div>
        Verifying authentication token.
        </div>
      </GlobalContext.Provider>
      {error && 
        <Paper style={{
          padding: "0.5rem"
        }}>
          <Typography component="h2" sx={{ color: 'red' }} >
            {error}
          </Typography>
        </Paper>
      }
    </Paper>
  );
}

export default Group;
