import React, { useContext, useState, useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as ReactLink, useNavigate } from "react-router-dom";

import { GlobalContext } from "./GlobalContext.js";
import { base } from "./Common.js";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://ketrenos.com/">
        ketrenos.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const { setUser, csrfToken } = useContext(GlobalContext);
  const [ error, setError ] = useState(undefined);
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    const res = await window.fetch(`${base}/api/v1/users/signin`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        email: form.get('email'),
        password: form.get('password')
      })
    });
    const data = await res.json();
    if (res.status >= 400) {
      setError(data.message ? data.message : res.statusText);
      return null;
    }
    
    if (!data) {
      return;
    }
    setUser(data);
    navigate("/");
  }, [csrfToken, navigate, setUser ]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{
        width: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <div
          style={{
            marginTop: 8,
            display: 'flex',
            width: '40rem',
            flexDirection: 'column',
            alignItems: 'left',
            textAlign: 'left'
          }}
        >
          <Typography>
            <p>While those familiar with the original Beer App may recall
              an account was not needed to particpate, now that
              the audience is larger, events and contact information for
              those participating are now restricted to only those that
              have verified an email address and created an account
              on Goodtimes.</p>

            <p>Contact information is only shared with those that are in
              groups that you join.</p>
          </Typography>
        </div>
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
       
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {error &&
              <Typography component="h2" sx={{ color: 'red' }} >
                {error}
              </Typography>
            }
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link component={ReactLink} to="/password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link component={ReactLink} to="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}